var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 24 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { stroke: _vm.color, "stroke-width": _vm.weight } }, [
        _c(
          "path",
          {
            attrs: {
              d: "",
              "stroke-linejoin": "round",
              "stroke-linecap": "round",
            },
          },
          [
            _c("animate", {
              attrs: {
                attributeName: "d",
                values:
                  "M12.6667 11.3333C12 10.6667 12 10 12 10C12 10 12 10.6667 11.3333 11.3333C10.6667 12 10 12 10 12C10 12 10.6667 12 11.3333 12.6667C12 13.3333 12 14 12 14C12 14 12 13.3333 12.6667 12.6667C13.3333 12 14 12 14 12C14 12 13.3333 12 12.6667 11.3333Z;M15 9C12 6 12 3 12 3C12 3 12 6 9 9C6 12 3 12 3 12C3 12 6 12 9 15C12 18 12 21 12 21C12 21 12 18 15 15C18 12 21 12 21 12C21 12 18 12 15 9Z;M12.6667 11.3333C12 10.6667 12 10 12 10C12 10 12 10.6667 11.3333 11.3333C10.6667 12 10 12 10 12C10 12 10.6667 12 11.3333 12.6667C12 13.3333 12 14 12 14C12 14 12 13.3333 12.6667 12.6667C13.3333 12 14 12 14 12C14 12 13.3333 12 12.6667 11.3333Z",
                dur: _vm.speed,
                repeatCount: "indefinite",
              },
            }),
          ]
        ),
        _c(
          "path",
          {
            attrs: {
              d: "",
              "stroke-linejoin": "round",
              "stroke-linecap": "round",
            },
          },
          [
            _c("animate", {
              attrs: {
                attributeName: "d",
                values:
                  "M19.6667 4.33333C18 2.66667 18 1 18 1C18 1 18 2.66667 16.3333 4.33333C14.6667 6 13 6 13 6C13 6 14.6667 6 16.3333 7.66667C18 9.33333 18 11 18 11C18 11 18 9.33333 19.6667 7.66667C21.3333 6 23 6 23 6C23 6 21.3333 6 19.6667 4.33333Z;M18.6667 5.33333C18 4.66667 18 4 18 4C18 4 18 4.66667 17.3333 5.33333C16.6667 6 16 6 16 6C16 6 16.6667 6 17.3333 6.66667C18 7.33333 18 8 18 8C18 8 18 7.33333 18.6667 6.66667C19.3333 6 20 6 20 6C20 6 19.3333 6 18.6667 5.33333Z;M19.6667 4.33333C18 2.66667 18 1 18 1C18 1 18 2.66667 16.3333 4.33333C14.6667 6 13 6 13 6C13 6 14.6667 6 16.3333 7.66667C18 9.33333 18 11 18 11C18 11 18 9.33333 19.6667 7.66667C21.3333 6 23 6 23 6C23 6 21.3333 6 19.6667 4.33333Z",
                dur: _vm.speed,
                repeatCount: "indefinite",
              },
            }),
          ]
        ),
        _c(
          "path",
          {
            attrs: {
              d: "",
              "stroke-linejoin": "round",
              "stroke-linecap": "round",
            },
          },
          [
            _c("animate", {
              attrs: {
                attributeName: "d",
                values:
                  "M7.66667 16.3333C6 14.6667 6 13 6 13C6 13 6 14.6667 4.33333 16.3333C2.66667 18 1 18 1 18C1 18 2.66667 18 4.33333 19.6667C6 21.3333 6 23 6 23C6 23 6 21.3333 7.66667 19.6667C9.33333 18 11 18 11 18C11 18 9.33333 18 7.66667 16.3333Z;M6.66667 17.3333C6 16.6667 6 16 6 16C6 16 6 16.6667 5.33333 17.3333C4.66667 18 4 18 4 18C4 18 4.66667 18 5.33333 18.6667C6 19.3333 6 20 6 20C6 20 6 19.3333 6.66667 18.6667C7.33333 18 8 18 8 18C8 18 7.33333 18 6.66667 17.3333Z;M7.66667 16.3333C6 14.6667 6 13 6 13C6 13 6 14.6667 4.33333 16.3333C2.66667 18 1 18 1 18C1 18 2.66667 18 4.33333 19.6667C6 21.3333 6 23 6 23C6 23 6 21.3333 7.66667 19.6667C9.33333 18 11 18 11 18C11 18 9.33333 18 7.66667 16.3333Z",
                dur: _vm.speed,
                repeatCount: "indefinite",
              },
            }),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }