var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.agent
    ? _c("div", [
        _c(
          "div",
          { staticClass: "text-body-2 grey--text text--darken-2 mb-6" },
          [_vm._v(" " + _vm._s(_vm.$t("livechat.profile.description")) + " ")]
        ),
        false
          ? _c("div", { staticClass: "admin--text text-copy-2 mb-4" }, [
              _vm._v(
                " Pro Hub User gibt es aktuell einen Agenten. Geplant ist aber, dass pro Bot der Agent ein eigenes Profil hat, um Bild, Name und Titel entsprechend anzupassen. "
              ),
            ])
          : _vm._e(),
        _c("div", { staticClass: "d-sm-flex" }, [
          _c(
            "div",
            { staticStyle: { width: "150px" } },
            [
              _c("ImageUpload", {
                attrs: {
                  width: 150,
                  circleStyle: "",
                  imageSrc: _vm.profilePicture,
                  disabled: _vm.loading,
                },
                on: {
                  onUpload: function ($event) {
                    return _vm.uploadImage($event)
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "flex-grow-1 items-center pt-4",
              class: [_vm.$vuetify.breakpoint.smAndUp ? "ml-6" : ""],
            },
            [
              _c("v-text-field", {
                attrs: {
                  outlined: "",
                  dense: "",
                  label: _vm.$t("livechat.profile.nameLabel"),
                  type: "text",
                  required: "",
                  disabled: _vm.loading,
                },
                model: {
                  value: _vm.userName,
                  callback: function ($$v) {
                    _vm.userName = $$v
                  },
                  expression: "userName",
                },
              }),
              _c("CountryLanguageSelect", {
                attrs: { useLanguages: "" },
                model: {
                  value: _vm.selectedLanguages,
                  callback: function ($$v) {
                    _vm.selectedLanguages = $$v
                  },
                  expression: "selectedLanguages",
                },
              }),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }