var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-btn",
    _vm._g(
      {
        staticClass: "pa-0",
        class: [_vm.colorBtn === "admin" && "moin-admin-box"],
        style: _vm.small ? "min-width: 24px" : "min-width: 36px",
        attrs: {
          text: _vm.text,
          disabled: _vm.disabled,
          to: _vm.to,
          append: _vm.append,
          exact: _vm.exact,
          color: _vm.colorBtn,
          small: _vm.small,
          outlined: _vm.outlined,
          tile: _vm.tile,
        },
      },
      _vm.$listeners
    ),
    [
      _vm.withSlot
        ? _vm._t("default")
        : _c("v-icon", { attrs: { small: _vm.small, color: _vm.colorIcon } }, [
            _vm._v(_vm._s(_vm.icon)),
          ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }