// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/fonts/JosefinSans-VariableFont_wght.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("@/assets/fonts/Lato-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("@/assets/fonts/silka-semibold-webfont.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: \"Josefin Sans\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n@font-face {\n  font-family: \"Lato\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n@font-face {\n  font-family: \"silkaHeading\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n.chat-item-self[data-v-29d0389a] {\n  padding-left: 100px;\n}\n.chat-item-customer[data-v-29d0389a] {\n  padding-right: 100px;\n}\n.chat-item-time[data-v-29d0389a] {\n  width: 40px;\n}\n.chat-item-text[data-v-29d0389a] {\n  border-radius: 4px;\n  overflow: hidden;\n}\n.chat-item-spacer[data-v-29d0389a] {\n  width: 110px;\n}\n.chat-item-text-bg[data-v-29d0389a] {\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  opacity: 0.1;\n}\n@-webkit-keyframes blink-29d0389a {\n0% {\n    opacity: 1;\n}\n50% {\n    opacity: 0;\n}\n100% {\n    opacity: 1;\n}\n}\n@keyframes blink-29d0389a {\n0% {\n    opacity: 1;\n}\n50% {\n    opacity: 0;\n}\n100% {\n    opacity: 1;\n}\n}\n.translating[data-v-29d0389a] {\n  font-style: oblique;\n}\n.translating .blinking[data-v-29d0389a] {\n  -webkit-animation: blink-29d0389a 1s infinite;\n          animation: blink-29d0389a 1s infinite;\n}\n.agent-chat-item-bg[data-v-29d0389a] {\n  background-color: rgba(230, 230, 230, 0.5);\n}\n.chat-system-message-wrapper[data-v-29d0389a] {\n  position: relative;\n  margin-top: 20px;\n  margin-bottom: 20px;\n}\n.chat-system-message[data-v-29d0389a]:before {\n  position: absolute;\n  left: 0;\n  right: 0;\n  top: 50%;\n  content: \"\";\n  display: block;\n  height: 1px;\n  width: 100%;\n  border-top: 1px dashed var(--v-primary-lighten5);\n  opacity: 0.6;\n}\n.chat-system-message[data-v-29d0389a] {\n  position: relative;\n  display: inline-block;\n  padding: 0 20px;\n  width: 100%;\n  z-index: 1;\n}\n.chat-system-message-text[data-v-29d0389a] {\n  background: white;\n  position: relative;\n  z-index: 1;\n  padding: 0 10px;\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
