var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        [
          _c("v-text-field", {
            staticClass: "ma-4",
            attrs: {
              "append-icon": "mdi-magnify",
              label: "Search",
              "single-line": "",
              "hide-details": "",
            },
            model: {
              value: _vm.search,
              callback: function ($$v) {
                _vm.search = $$v
              },
              expression: "search",
            },
          }),
        ],
        1
      ),
      _c("v-data-table", {
        attrs: {
          items: _vm.preparedNotifications,
          headers: _vm.headers,
          loading: _vm.isLoading,
          "sort-by": ["updatedAt"],
          "sort-desc": [true],
          "multi-sort": true,
          "footer-props": {
            "items-per-page-options": [20, 50, -1],
          },
        },
        scopedSlots: _vm._u([
          {
            key: "item.createdAt",
            fn: function ({ item }) {
              return [
                _vm._v(" " + _vm._s(_vm.formatDateTime(item.createdAt)) + " "),
              ]
            },
          },
          {
            key: "item.updatedAt",
            fn: function ({ item }) {
              return [
                _vm._v(" " + _vm._s(_vm.formatDateTime(item.updatedAt)) + " "),
              ]
            },
          },
          {
            key: "item.recipients",
            fn: function ({ item }) {
              return _vm._l(item.recipients, function (recipient) {
                return _c(
                  "v-chip",
                  { staticClass: "ma-1", attrs: { small: "" } },
                  [_vm._v(" " + _vm._s(recipient) + " ")]
                )
              })
            },
          },
          {
            key: "item.status",
            fn: function ({ item }) {
              return [
                _c(
                  "v-chip",
                  {
                    staticClass: "ma-1",
                    attrs: {
                      color: _vm.isStatusSuccess(item.status)
                        ? "success"
                        : "error",
                      small: "",
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(`botNotifications.status.${item.status}`)
                        ) +
                        " "
                    ),
                  ]
                ),
              ]
            },
          },
          {
            key: "item.conversation",
            fn: function ({ item }) {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      title: _vm.$t(
                        "botNotifications.actions.openConversation"
                      ),
                      color: "primary",
                      text: "",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.openConversation(item)
                      },
                    },
                  },
                  [_c("v-icon", [_vm._v("message")])],
                  1
                ),
              ]
            },
          },
          {
            key: "item.actions",
            fn: function ({ item }) {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      "data-cy": `bot-notifications-${item._id}-btn`,
                      title: _vm.$t("botNotifications.actions.resend"),
                      color: "primary",
                      text: "",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.openResendNotification(item)
                      },
                    },
                  },
                  [_c("v-icon", [_vm._v("mdi-arrow-u-right-top")])],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }