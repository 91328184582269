var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app-bar",
    {
      attrs: {
        app: "",
        flat: "",
        color: "primary",
        dark: "",
        "extension-height": 60,
      },
      scopedSlots: _vm._u(
        [
          _vm.notification
            ? {
                key: "extension",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "white flex-grow-1 mx-n4 pt-2 px-2" },
                      [_c("HubNotification")],
                      1
                    ),
                  ]
                },
                proxy: true,
              }
            : null,
        ],
        null,
        true
      ),
    },
    [
      _c("BtnSquare", {
        staticClass: "hidden-lg-and-up ml-n2 mr-2",
        attrs: {
          icon: "menu",
          colorBtn: "white",
          colorIcon: "white",
          "data-cy": "drawer",
        },
        on: {
          click: function ($event) {
            return _vm.toggleMenuOpen()
          },
        },
      }),
      _vm.isMultiBot
        ? _c("HubBotSelect", {
            staticClass: "mr-2",
            style: {
              width: _vm.$vuetify.breakpoint.smAndDown ? "80px" : "250px",
            },
            attrs: { "data-cy": "app-bar-bot-select" },
          })
        : [
            _vm.currentBot
              ? _c(
                  "v-toolbar-title",
                  {
                    staticClass: "text-h6 hidden-sm-and-down mr-2 pl-lg-2",
                    attrs: { "data-cy": "app-bar-bot-title" },
                  },
                  [_vm._v(" " + _vm._s(_vm.currentBot.name) + " ")]
                )
              : _vm._e(),
          ],
      _c("v-spacer"),
      _c("SearchComponent", { staticStyle: { width: "320px" } }),
      _c("v-divider", {
        staticClass: "ml-4 mr-2",
        attrs: { vertical: "", inset: "" },
      }),
      _c("LivechatHeaderBtn"),
      _vm.hasWebchatChannel
        ? _c(
            "v-btn",
            {
              staticClass: "mx-2 px-2 white--text font-weight-bold",
              attrs: {
                outlined: "",
                dark: "",
                target: "_blank",
                to: "/preview/" + _vm.currentBot.stagingBot,
                "data-cy": "app-bar-preview-btn",
              },
              on: {
                click: function ($event) {
                  return _vm.handleBtnClick("preview")
                },
              },
            },
            [
              _c("span", { staticClass: "hidden-sm-and-down pl-2" }, [
                _vm._v("Preview"),
              ]),
              _c("v-icon", { staticClass: "pl-0 mx-2" }, [
                _vm._v("mdi-comment-processing-outline"),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c("DeploymentButton"),
      _vm.hasFacebookChannel
        ? _c(
            "v-btn",
            {
              attrs: { href: _vm.facebookChannel, icon: "", target: "_blank" },
            },
            [_c("v-icon", [_vm._v("mdi-facebook-messenger")])],
            1
          )
        : _vm._e(),
      !_vm.isNoBeamer
        ? _c("BtnSquare", {
            staticClass: "notification-trigger mr-n2",
            attrs: { icon: "info", colorBtn: "white", colorIcon: "white" },
            on: {
              click: function ($event) {
                return _vm.toggleMenuOpen()
              },
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }