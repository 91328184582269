var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.channels && _vm.channels.length > 1
    ? _c(
        "div",
        { staticClass: "moin-fresh" },
        [
          _c("v-select", {
            staticStyle: { "min-width": "240px" },
            attrs: {
              outlined: "",
              dense: "",
              flat: "",
              "hide-details": "",
              value: _vm.selectedChannelId,
              items: _vm.channels,
              "item-value": "channelId",
              "item-text": "displayName",
              disabled: _vm.disabled,
              "item-disabled": "blocked",
            },
            on: { change: _vm.changeChannelId },
            scopedSlots: _vm._u(
              [
                {
                  key: "selection",
                  fn: function ({ item }) {
                    return [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-space-between full-width",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "text-truncate",
                              style: {
                                maxWidth: _vm.$vuetify.breakpoint.smOnly
                                  ? "210px"
                                  : "auto",
                              },
                            },
                            [
                              _vm._v(" " + _vm._s(item.displayName) + " "),
                              _vm.draftAvailable(item)
                                ? _c(
                                    "v-tooltip",
                                    {
                                      attrs: {
                                        bottom: "",
                                        color: "primary lighten-3",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        staticClass:
                                                          "secondary--text pl-2 mb-1",
                                                        attrs: {
                                                          small: "",
                                                          left: "",
                                                        },
                                                      },
                                                      "v-icon",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [_vm._v(" edit ")]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("common.unsavedChanges")
                                          )
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c("div", [
                            _c("span", {
                              staticClass:
                                "ml-auto mr-2 text-caption align-self-center",
                              domProps: {
                                textContent: _vm._s(_vm.languageCode(item)),
                              },
                            }),
                            _c(
                              "span",
                              { staticClass: "mr-1" },
                              [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: {
                                      bottom: "",
                                      color: "primary lighten-3",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        small: "",
                                                        color:
                                                          "primary lighten-2",
                                                      },
                                                    },
                                                    "v-icon",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$channelIcon(item)
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.channelTypeDescription(
                                            item.channel
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                    ]
                  },
                },
                {
                  key: "item",
                  fn: function ({ item }) {
                    return [
                      _c(
                        "span",
                        { staticClass: "ml-0" },
                        [
                          _vm._v(" " + _vm._s(item.displayName) + " "),
                          !item.displayName
                            ? _c("span", [_vm._v("Kein Name")])
                            : _vm._e(),
                          _vm.draftAvailable(item)
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: {
                                    bottom: "",
                                    color: "primary lighten-3",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-icon",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass:
                                                      "secondary--text text--darken-2 pl-2 mb-1",
                                                    attrs: {
                                                      small: "",
                                                      left: "",
                                                    },
                                                  },
                                                  "v-icon",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [_vm._v(" edit ")]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.$t("common.unsavedChanges"))
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c("span", {
                        staticClass:
                          "ml-auto mr-2 text-caption align-self-center",
                        domProps: {
                          textContent: _vm._s(_vm.languageCode(item)),
                        },
                      }),
                      _c(
                        "span",
                        { staticClass: "mr-1" },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "", color: "primary lighten-3" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-icon",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass: "mb-1",
                                                attrs: {
                                                  color: "primary lighten-2",
                                                  small: "",
                                                },
                                              },
                                              "v-icon",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$channelIcon(item)) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.channelTypeDescription(item.channel)
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              2061129836
            ),
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }