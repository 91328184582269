var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "HubBox",
    { attrs: { title: _vm.$t("common.general"), boxId: "intents-general" } },
    [
      _c("div", { staticClass: "text-body-2" }, [
        _vm._v(" " + _vm._s(_vm.$t("intent.general.description")) + " "),
      ]),
      _c("div", { staticClass: "mt-4" }, [
        _c(
          "p",
          { staticClass: "primary--text text--lighten-3 mb-1 text-body-2" },
          [_vm._v(" " + _vm._s(_vm.$t("intent.name")) + " ")]
        ),
        _c("div", [_vm._v(_vm._s(_vm.intent.displayName))]),
      ]),
      _c("div", { staticClass: "mt-4" }, [
        _c(
          "p",
          { staticClass: "primary--text text--lighten-3 mb-1 text-body-2" },
          [_vm._v(" " + _vm._s(_vm.$t("intent.description")) + " ")]
        ),
        _c("div", [_vm._v(_vm._s(_vm.intent.description))]),
      ]),
      _c(
        "div",
        { staticClass: "mt-6" },
        [
          _c(
            "v-btn",
            {
              attrs: { outlined: "", color: "primary lighten-3" },
              on: {
                click: function ($event) {
                  _vm.editDialogOpen = true
                },
              },
            },
            [
              _vm._v(" " + _vm._s(_vm.$t("common.edit")) + " "),
              _c("v-icon", { attrs: { right: "" } }, [_vm._v("edit")]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "HubDialog",
        {
          attrs: {
            value: _vm.editDialogOpen,
            "primary-label": _vm.$t("common.save"),
            "primary-icon": "save",
            "secondary-label": _vm.$t("common.cancel"),
            "secondary-icon": "close",
            title: "Generelles bearbeiten",
            modal: "",
          },
          on: { onPrimary: _vm.saveEdit, onSecondary: _vm.cancelEdit },
        },
        [
          _c(
            "div",
            [
              _c(
                "v-row",
                { staticClass: "mt-4" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("div"),
                      _c("v-text-field", {
                        staticClass: "mb-2",
                        attrs: {
                          outlined: "",
                          color: "secondary darken-2",
                          autofocus: "",
                          "hide-details": "",
                        },
                        model: {
                          value: _vm.copyIntent.displayName,
                          callback: function ($$v) {
                            _vm.$set(_vm.copyIntent, "displayName", $$v)
                          },
                          expression: "copyIntent.displayName",
                        },
                      }),
                      _c("InfoBox", {
                        attrs: {
                          text: _vm.$t("intentNew.stepName.displayName.hint"),
                          icon: null,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "mt-4" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-textarea", {
                        staticClass: "mb-2",
                        attrs: {
                          outlined: "",
                          color: "secondary darken-2",
                          "hide-details": "",
                          readonly: !_vm.isAdmin,
                          disabled: !_vm.isAdmin,
                        },
                        model: {
                          value: _vm.copyIntent.description,
                          callback: function ($$v) {
                            _vm.$set(_vm.copyIntent, "description", $$v)
                          },
                          expression: "copyIntent.description",
                        },
                      }),
                      _c("InfoBox", {
                        attrs: {
                          text: _vm.$t("intentNew.stepName.description.hint"),
                          icon: null,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }